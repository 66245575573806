import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const showPopup = (content) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
    setPopupContent(null);
  };

  return (
    <PopupContext.Provider value={{ isPopupVisible, popupContent, showPopup, hidePopup }}>
        <div className='h-full'>
            {children}
        </div>
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);