import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { classNames } from "@basecomponent";
import logo from "../../assets/theme/logo.png";
import { Dialog, Transition } from "@headlessui/react";
import { usePopup } from '../../popup-fragment/popupcontext';

const SideBar = (props) => {
  let { routes, session } = props;
  const location = useLocation();
  const [update, setUpdate] = React.useState(false);
  const [openItems, setOpenItems] = React.useState({});
  
  // creates the links that appear in the left menu
  const CreateLinks = (str) => {
    const { showPopup } = usePopup();
    var routesNew = _.filter(routes, function (list) {
      return (
        list.display &&
        list.layout === "/admin" &&
        list.position === str &&
        (list.section === "both" || list.section === session?.usertype)
      );
    }); // custom check is login not display in sidebar

    React.useEffect(() => {
      // This effect will run after openItems state is updated
    }, [update]);

    // checkking mq teacher or student hide side menu
    var hideSideMenu = [];
    if (session?.mqstudent === true) {
      hideSideMenu = ["messages"];
    }
    if (session?.userid === 1) {
      hideSideMenu = [
        "dashboard",
        "students",
        "questionsets",
        "reports",
        "messages",
      ];
    }

    _.forEach(hideSideMenu, (obj) => {
      routesNew = _.filter(routesNew, (o) => {
        return o.key !== obj;
      });
    });
    // -------------------------------- //

    return routesNew.map((item, key) => {
      var selected = _.includes(location.pathname, item.path);
      if(item?.search && location.search !== item?.search) selected = false
      return (
        <>
          {item?.submenu === undefined ? (
            <a
              key={item.name}
              href={`#${item.path}${item?.search ? item.search : ''}`}
              className={classNames(
                selected
                  ? "bg-theme text-white"
                  : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300",
                "text-lg font-normal group flex items-center px-2.5 py-4 rounded-xl space-x-3 mt-2"
              )}
              aria-current={selected ? "page" : undefined}
            >
              <img
                className="flex-shrink-0 h-6 w-6"
                src={selected ? item.selected : item.icon}
              />

              {/* <item.icon
                className={classNames(
                  selected ? "text-white" : "text-secondary group-hover:text-white",
                  "flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
              /> */}

              <div>{item.name}</div>
            </a>)
            : (
              <>
                <div
                  className={classNames(
                    item.isOpen
                      ? "bg-theme text-white rounded-t-xl"
                      : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300 rounded-xl",
                    "text-lg font-normal group flex items-center px-2.5 py-4  space-x-3 select-none mt-2"
                  )}
                  onClick={() => {
                    item.isOpen = !item.isOpen;
                    setUpdate(!update);
                     // Délai de 100ms avant de mettre à jour l'état
                  }}
                >
                  <img className="flex-shrink-0 h-6 w-6" src={selected ? item.selected : item.icon}/>
                  <div>{item.name} {item.isOpen == true ? "˅" : ">"}</div>
                  
                </div>
               
                  <Transition
                        show={item.isOpen}
                        className="overflow-hidden bg-gray-300 mt-0 space-y-0 rounded-b-xl"
                        enter="transition-all duration-500 ease-in"
                        enterFrom={`max-h-0`}
                        enterTo={`max-h-full`}
                        leave="transition-all duration-500 ease-out"
                        leaveFrom={`max-h-full`}
                        leaveTo={`max-h-0`}
                    >
                    
                      <ul className="inline-block w-full">
                        {Object.keys(item.submenu).map((key) => (
                          <li className={classNames(
                            selected
                              ? "bg-theme text-white"
                              : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300",
                            "text-lg font-normal group flex items-center px-2.5 py-4 pl-10"
                            )} 
                            key={key}
                            onClick={() => {
                              showPopup(item.component);
                            }}
                          >
                            <img className="flex-shrink-0 h-6 w-6" src={selected ? item.selected : item.icon}/>
                            {key}
                            
                            </li>
                        ))}
                      </ul>
                      
                  </Transition>
                  
                
              
              </>
            )
          }
        </>
      );
    });
  };

  return (
    <>
      {/* Static sidebar for desktop */}

      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow bg-sidebar overflow-y-auto">
        <div className="flex items-center justify-center flex-shrink-0 px-4 py-12">
          <a href={session?.userid === 1 ? "/#/classes" : "/#/dashboard"}>
            <img className="block h-16 w-auto" src={logo} alt="Workflow" />
          </a>
        </div>
        <nav
          className="mt-3 px-7 flex-1 flex flex-col overflow-y-auto"
          aria-label="Sidebar"
        >
          <div>{CreateLinks("main")}</div>
          {session?.userid === 1 ? (
            ""
          ) : (
            <>
              <div className="py-6 text-base font-semibold">Raccourcis</div>
              <div className="space-y-2">{CreateLinks("second")}</div>
            </>
          )}
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { isLoggedIn: state.isLoggedIn, session: state.session };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
