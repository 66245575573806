import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/react/solid";
import { DocumentSearchIcon } from "@heroicons/react/outline";
import { confirmAlert } from "react-confirm-alert";
import Lightbox from "react-image-lightbox";
import { BASE_IMAGES_URL } from "@constant";
import { classNames } from "@basecomponent";
import {
  insertAttemptQuestion,
  fetchAttemptedSetQstList,
  attemptedSetFinished,
  askForNewQuestion,
  askForHelp
} from "@questionsetsaction";
import CardInfo from "../../common/cardInfo";
import LocaleStrings from "@language";
import ResponseSet from './responseset'
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { XIcon } from "@heroicons/react/outline";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import { renderToString } from 'react-dom/server';
import Markdown from "../../Markdown";
import ReactDOMServer from 'react-dom/server';
//

const content = `
Pour expliquer à un élève de lycée la valeur de la racine carrée de leur produit $ \\sqrt{ab} $, voici une liste numérotée des étapes à suivre :

1. **Comprendre la racine carrée :** La racine carrée d'un nombre est un autre nombre qui, lorsqu'il est multiplié par lui-même, donne le nombre original. Par exemple, la racine carrée de 9 est 3, car $3 \\times 3 = 9$.

2. **Propriétés des racines carrées :** La racine carrée d'un produit de deux nombres positifs est égale au produit des racines carrées de chaque nombre. Cela signifie que $ \\sqrt{ab} = \\sqrt{a} \\times \\sqrt{b} $.

3. **Application de la propriété :** Si $a$ et $b$ sont des nombres positifs, alors la racine carrée de leur produit est simplement le produit de la racine carrée de $a$ et la racine carrée de $b$.

4. **Exemple concret :** Si $a = 4$ et $b = 9$, alors $ \\sqrt{ab} = \\sqrt{4 \\times 9} = \\sqrt{36} = 6 $. Et si on décompose cela avec la propriété, on obtient $ \\sqrt{4} \times \sqrt{9} = 2 \\times 3 = 6 $, ce qui est le même résultat.

5. **Cas particuliers :** Si l'un des nombres est zéro, alors le produit est zéro et la racine carrée de zéro est zéro. Cela signifie que si $a=0$ ou $b=0$, alors $ \\sqrt{ab} = 0 $.

6. **Fonction de deux variables :** La racine carrée de $ab$ peut être représentée comme une fonction de deux variables, $f(a, b) = \\sqrt{ab}$. Cette fonction est paire, ce qui signifie que $f(a, b) = f(-a, -b)$.

7. **Représentation graphique :** La fonction $ \\sqrt{ab} $ peut être représentée graphiquement. Les graphiques en 3D montrent la partie réelle et la partie imaginaire de la fonction.

   - Partie réelle : <img class='mx-auto' src='http://api.kojyto.fr:8125/assets/wf/899558e6-9a7a-47f6-9422-15b2d6cb09de.png' />
   - Partie imaginaire : <img class='mx-auto' src='http://api.kojyto.fr:8125/assets/wf/5fc49b1c-53c3-4589-88cd-a3575715f621.png' />

8. **Dérivée et intégrale :** Pour des calculs plus avancés, on peut déterminer la dérivée de la fonction par rapport à $a$ ou $b$, ainsi que l'intégrale indéfinie de la fonction.

9. **Séries :** Pour des valeurs spécifiques de $a$ et $b$, on peut exprimer $ \\sqrt{ab} $ en utilisant des séries de Puiseux ou d'autres séries, mais cela dépasse généralement le niveau du lycée.

10. **Conclusion :** En résumé, pour des nombres positifs $a$ et $b$, la racine carrée de leur produit $ \sqrt{ab} $ est égale au produit de leurs racines carrées individuelles, soit $ \\sqrt{a} \\times \\sqrt{b} $.
`;

const ItemList = (props) => {
  let {
    session,
    listitem,
    attemptSetModal,
    currentPage,
    pageDataLength,
    setDetails,
    attemptedSetQuestionList,
    askForNewQuestion,
    askForHelp
  } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedMcqAns, setSelectedMcqAns] = useState("");
  const [isPhotoOpen, setisPhotoOpen] = useState({});
  const [responseValue, setResponseValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [restrictedInput, setRestrictedInput] = useState(false);
  const [isLoadingRequest, setIsloadingRequest] = useState(false);
  const [questionHelp, setQuestionHelp] = useState("");
  let imagePreviewUrl = "";
  if (listitem?.image) {
    imagePreviewUrl = `${BASE_IMAGES_URL}/${listitem?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`;
  }
  var attemptedAnswer = _.filter(attemptedSetQuestionList?.data, (obj) => {
    return (
      obj.attemptedsetsidfk == attemptSetModal?.id &&
      obj.questionidfk == listitem?.questionid
    );
  });

  // console.log("currentPage :- ", currentPage);

  // console.log("attemptedAnswer :- ", attemptedAnswer);
  // console.log("attemptSetModal :- ", attemptSetModal);

  useEffect(() => {
    var card = document.querySelector(".card");
    card?.classList?.remove("is-flipped");
    setIsFlipped(false);
    setSelectedMcqAns("");
    setisPhotoOpen({});

    if (attemptedAnswer?.[0]?.mcqanswer) {
      setSelectedMcqAns(attemptedAnswer?.[0]?.mcqanswer);
    }
  }, [currentPage,listitem]);

  const handlePagination = (pageNum) => {
    props.pagination(pageNum);
    setRestrictedInput(false)
  };

  const cardCallback = function(resp){
    if(resp == true){
      cardFlip({},true)
    }
  }

  const handleFinish = (type) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-alert-body">
            <div className="confirmation-title">
              {LocaleStrings.setsattempt_finish_quiz_title}
            </div>
            <div className="confirmation-subtitle">
              {LocaleStrings.setsattempt_finish_quiz_text}
            </div>

            <div className="custom-confirm-alert-button-group">
              <button className="confirmation-action-button" onClick={onClose}>
                {LocaleStrings.button_no}
              </button>
              <button
                className="confirmation-action-button"
                onClick={() => {
                  onClose();
                  props.callbacksetfinish();
                  props.attemptedSetFinished({ show: true, mode: type });
                }}
              >
                {LocaleStrings.button_yes}
              </button>
            </div>
          </div>
        );
      },
    });
  };


  const convertForMath = (question) => {
    if(question.includes("```math")){
      /* /(?<=```math)(.*)(?=```)/g  */
      var finalHtml = ""
      const startString = question.split("```math")
      const endString = question.replace('```math', "").split("```")
      const finalData = []
      for(const k in startString){
        if(startString[k].includes("```")){
          let first = true
          for (const v of startString[k].split("```")){
            if(first == true){
              finalData.push({type: "math", content: v})
              first = false
            }else{
              finalData.push({type: "text", content: v})
            }
          }
        }else{
          finalData.push({type: "text",content: startString[k]})
        }
      }

      
      //mettre tout en html convertir inlinemath en html et concatener avec final data
      finalData.map((data,key) =>
        finalHtml += data.type === "text"
          ? data.content
          : ReactDOMServer.renderToStaticMarkup(<InlineMath math={data.content} />)
        
      );
     
      return finalHtml;
    }


    return question
  }
  
  const handleImageView = (e, action) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === "open") {
      setisPhotoOpen({
        show: true,
        questionid: listitem?.questionid,
      });
    } else {
      setisPhotoOpen({
        show: false,
      });
    }
  };

  const cardFlip = (e, force = false) => {
    if(force == false && !isFlipped && responseValue == "" && attemptSetModal?.mode === "assessment"){
      setIsOpen(true)
    } else{
      if (!isFlipped && attemptSetModal?.mode === "practice") {
        var values = {
          attemptedsetsidfk: attemptSetModal?.id,
          questionidfk: listitem?.questionid,
        };
        props.insertAttemptQuestion(session, values, (res) => {
          // console.log("res :- ", res);
          props.fetchAttemptedSetQstList(session, attemptSetModal?.id);
        });
      }

      if(!isFlipped){
        setRestrictedInput(true)
      }
      var card = document.querySelector(".card");
      card?.classList?.toggle("is-flipped");
      setIsFlipped(!isFlipped);
    }

};


  const clickAskAnotherQuestion = (data) =>{
    setIsloadingRequest(true)
    askForNewQuestion(session,data, function(resp){
      setIsloadingRequest(false)
      //const newlistitem = listitem
      if(resp !== false){
        listitem.question = resp.question
        listitem.answer = resp.response
        //listitem = newlistitem
        this.forceUpdate()
      }

    })
  }

  const clickAskHelp = (data) =>{
    setIsloadingRequest(true)
    askForHelp(session,data, function(resp){
      setIsloadingRequest(false)
      //const newlistitem = listitem
      if(resp !== false){
        console.log(resp)
        setQuestionHelp(resp)
        //listitem = newlistitem
        this.forceUpdate()
      }

    })
  }

  const handleAnswer = (ans) => {
    // console.log("ans :- ", ans);
    if(isFlipped) setIsFlipped(!isFlipped);

    var values = {
      attemptedsetsidfk: attemptSetModal?.id,
      questionidfk: listitem?.questionid,
      answer: ans,
      response: responseValue,
    };
    // console.log("values :- ", values);

    props.insertAttemptQuestion(session, values, (res) => {
      // console.log("res :- ", res);
      props.fetchAttemptedSetQstList(session, attemptSetModal?.id);
    });
    setResponseValue("")
    setRestrictedInput(false)

    const t = currentPage * 1 >= pageDataLength && attemptedAnswer?.length == 0? null: handlePagination(currentPage + 1)
    // var card = document.querySelector(".card");
    // card.classList.remove("is-flipped");
    // setIsFlipped(!isFlipped);
  };

  const handleMcqAnswer = (e) => {
    var mcqans = e.target.value;
    var ans = "incorrect";
    if (mcqans == listitem?.answer) {
      ans = "correct";
    }
    // console.log("mcqans :- ", mcqans);
    // console.log("listitem :- ", listitem);

    setSelectedMcqAns(mcqans);

    var values = {
      attemptedsetsidfk: attemptSetModal?.id,
      questionidfk: listitem?.questionid,
      mcqanswer: mcqans,
      answer: ans,
    };
    // console.log("values :- ", values);

    props.insertAttemptQuestion(session, values, (res) => {
      // console.log("res :- ", res);
      props.fetchAttemptedSetQstList(session, attemptSetModal?.id);
    });
  };

  return (
    <>

      {/*AI HELP PART*/}
        {questionHelp !== "" && 
          <div className="z-50 mx-auto sm:w-3/4 md:w-3/4 lg:w-2/4 fixed h-50 inset-0 flex items-center ">
            <div className="xs:h-5/6 md:h-4/6 lg:h-4/6 sm:h-full w-full inset-0 bg-default shadow-2xl rounded bg-opacity-95 transition-opacity pt-20 relative ">
                <div className="flex items-center">
                    <button
                        onClick={() => setQuestionHelp("")}
                        type="button"
                        className="outline-none absolute right-0 top-0 mt-4 mr-4 focus:outline-none"
                    >
                        <span className="sr-only">Close panel</span>
                        <XIcon
                        className="h-5 w-5 2xs:h-7 2xs:w-7 text-secondary"
                        aria-hidden="true"
                        />
                    </button>
                </div>

                <div className=" h-full scroll-y-auto text-xl p-2 overflow-y-auto">
                  <Markdown >{questionHelp}</Markdown>
                </div>
              </div>
          </div>
        }
      {/* <div className="2xs:flex 2xs:items-center 2xs:space-x-6 space-y-4 2xs:space-y-0 mb-6 mt-1">
        <span className="text-xl 2xs:text-2xl font-normal text-secondary">
          {LocaleStrings.by} {setDetails?.users_by_createdby?.username}
        </span>
        <div className="flex items-center space-x-6">
          <div>
            <span className="flex-shrink-0 inline-block px-6 py-0.5 text-xl 2xs:text-2xl font-normal text-theme bg-blue-100 rounded capitalize">
              {listitem?.difficulty}
            </span>
          </div>
          <div>
            <span className="flex-shrink-0 inline-block px-6 py-0.5 text-xl 2xs:text-2xl font-normal text-theme bg-blue-100 rounded capitalize">
              {listitem?.skill}
            </span>
          </div>
        </div>
      </div> */}

      <div className="bg-white border rounded-lg shadow-lg w-full sm:w-36rem">
        <div className="scene scene--card">
          <div className={classNames("card")}>
            <div className="card__face card__face--front">
              <div className="p-5 min-h-10rem">
                <div className="space-y-2">
                  <div className="text-xl 2xs:text-2xl font-normal text-secondary">
                    {LocaleStrings.term} {currentPage}
                  </div>
                  <div className="space-y-2">
                    {imagePreviewUrl !== "" ? (
                      <div className="relative image-section image-display h-40 mb-2 rounded-lg border">
                        <img
                          className="h-full w-full object-contain inline-block rounded-lg"
                          src={imagePreviewUrl}
                          alt=""
                        />
                        <DocumentSearchIcon
                          className="absolute -top-10 -right-1 w-7 h-7 cursor-default text-secondary shadow-2xl"
                          onClick={(e) => handleImageView(e, "open")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-2xl 2xs:text-3xl font-normal text-primary break-words" dangerouslySetInnerHTML={{__html:convertForMath(listitem?.question)}}>
                    </div>
                  </div>
                </div>
                {listitem?.questionmode === "mcq" ? (
                  <div className="mt-5 space-y-2">
                    <div className="w-full flex items-center">
                      {attemptSetModal?.mode === "practice" ? (
                        <span className="text-base 2xs:text-xl font-normal text-secondary">
                          A.
                        </span>
                      ) : (
                        <>
                          {selectedMcqAns === "" ? (
                            <input
                              name="mcqanswer"
                              className="radio-field"
                              type="radio"
                              value={listitem?.option1}
                              onChange={handleMcqAnswer}
                            />
                          ) : (
                            <span
                              className={classNames(
                                (listitem?.answer === selectedMcqAns &&
                                  listitem?.option1 === selectedMcqAns) ||
                                  (listitem?.option1 !== selectedMcqAns &&
                                    listitem?.option1 === listitem?.answer)
                                  ? "bg-green-100"
                                  : listitem?.option1 === selectedMcqAns &&
                                    listitem?.option1 !== listitem?.answer
                                  ? "bg-red-100"
                                  : "bg-gray-100",
                                "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                              )}
                              aria-hidden="true"
                            >
                              <span
                                className={classNames(
                                  (listitem?.answer === selectedMcqAns &&
                                    listitem?.option1 === selectedMcqAns) ||
                                    (listitem?.option1 !== selectedMcqAns &&
                                      listitem?.option1 === listitem?.answer)
                                    ? "bg-green-400"
                                    : listitem?.option1 === selectedMcqAns &&
                                      listitem?.option1 !== listitem?.answer
                                    ? "bg-red-400"
                                    : "bg-gray-400",
                                  "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                                )}
                              />
                            </span>
                          )}
                        </>
                      )}
                      <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                        {listitem?.option1}
                      </span>
                    </div>
                    <div className="w-full flex items-center">
                      {attemptSetModal?.mode === "practice" ? (
                        <span className="text-base 2xs:text-xl font-normal text-secondary">
                          B.
                        </span>
                      ) : (
                        <>
                          {selectedMcqAns === "" ? (
                            <input
                              name="mcqanswer"
                              className="radio-field"
                              type="radio"
                              value={listitem?.option2}
                              onChange={handleMcqAnswer}
                            />
                          ) : (
                            <span
                              className={classNames(
                                (listitem?.answer === selectedMcqAns &&
                                  listitem?.option2 === selectedMcqAns) ||
                                  (listitem?.option2 !== selectedMcqAns &&
                                    listitem?.option2 === listitem?.answer)
                                  ? "bg-green-100"
                                  : listitem?.option2 === selectedMcqAns &&
                                    listitem?.option2 !== listitem?.answer
                                  ? "bg-red-100"
                                  : "bg-gray-100",
                                "h-4 w-4 2xs:h-7 2xs:w-7  rounded-full flex items-center justify-center"
                              )}
                              aria-hidden="true"
                            >
                              <span
                                className={classNames(
                                  (listitem?.answer === selectedMcqAns &&
                                    listitem?.option2 === selectedMcqAns) ||
                                    (listitem?.option2 !== selectedMcqAns &&
                                      listitem?.option2 === listitem?.answer)
                                    ? "bg-green-400"
                                    : listitem?.option2 === selectedMcqAns &&
                                      listitem?.option2 !== listitem?.answer
                                    ? "bg-red-400"
                                    : "bg-gray-400",
                                  "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                                )}
                              />
                            </span>
                          )}
                        </>
                      )}
                      <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                        {listitem?.option2}
                      </span>
                    </div>
                    {listitem?.option3 ? (
                      <div className="w-full flex items-center">
                        {attemptSetModal?.mode === "practice" ? (
                          <span className="text-base 2xs:text-xl font-normal text-secondary">
                            C.
                          </span>
                        ) : (
                          <>
                            {selectedMcqAns === "" ? (
                              <input
                                name="mcqanswer"
                                className="radio-field"
                                type="radio"
                                value={listitem?.option3}
                                onChange={handleMcqAnswer}
                              />
                            ) : (
                              <span
                                className={classNames(
                                  (listitem?.answer === selectedMcqAns &&
                                    listitem?.option3 === selectedMcqAns) ||
                                    (listitem?.option3 !== selectedMcqAns &&
                                      listitem?.option3 === listitem?.answer)
                                    ? "bg-green-100"
                                    : listitem?.option3 === selectedMcqAns &&
                                      listitem?.option3 !== listitem?.answer
                                    ? "bg-red-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    (listitem?.answer === selectedMcqAns &&
                                      listitem?.option3 === selectedMcqAns) ||
                                      (listitem?.option3 !== selectedMcqAns &&
                                        listitem?.option3 === listitem?.answer)
                                      ? "bg-green-400"
                                      : listitem?.option3 === selectedMcqAns &&
                                        listitem?.option3 !== listitem?.answer
                                      ? "bg-red-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                                  )}
                                />
                              </span>
                            )}
                          </>
                        )}
                        <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                          {listitem?.option3}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {listitem?.option4 ? (
                      <div className="w-full flex items-center">
                        {attemptSetModal?.mode === "practice" ? (
                          <span className="text-base 2xs:text-xl font-normal text-secondary">
                            D.
                          </span>
                        ) : (
                          <>
                            {selectedMcqAns === "" ? (
                              <input
                                name="mcqanswer"
                                className="radio-field"
                                type="radio"
                                value={listitem?.option4}
                                onChange={handleMcqAnswer}
                              />
                            ) : (
                              <span
                                className={classNames(
                                  (listitem?.answer === selectedMcqAns &&
                                    listitem?.option4 === selectedMcqAns) ||
                                    (listitem?.option4 !== selectedMcqAns &&
                                      listitem?.option4 === listitem?.answer)
                                    ? "bg-green-100"
                                    : listitem?.option4 === selectedMcqAns &&
                                      listitem?.option4 !== listitem?.answer
                                    ? "bg-red-100"
                                    : "bg-gray-100",
                                  "h-4 w-4 2xs:h-7 2xs:w-7 rounded-full flex items-center justify-center"
                                )}
                                aria-hidden="true"
                              >
                                <span
                                  className={classNames(
                                    (listitem?.answer === selectedMcqAns &&
                                      listitem?.option4 === selectedMcqAns) ||
                                      (listitem?.option4 !== selectedMcqAns &&
                                        listitem?.option4 === listitem?.answer)
                                      ? "bg-green-400"
                                      : listitem?.option4 === selectedMcqAns &&
                                        listitem?.option4 !== listitem?.answer
                                      ? "bg-red-400"
                                      : "bg-gray-400",
                                    "h-2 w-2 2xs:h-4 2xs:w-4 rounded-full"
                                  )}
                                />
                              </span>
                            )}
                          </>
                        )}
                        <span className="ml-3 text-base 2xs:text-xl font-normal text-secondary break-words">
                          {listitem?.option4}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="card__face card__face--back">
              <div className="p-5 min-h-10rem flex items-center justify-center">
                <div className="text-2xl 2xs:text-3xl font-normal text-primary break-words" dangerouslySetInnerHTML={{__html:convertForMath(listitem?.answer)}}>
                </div>
              </div>
            </div>
          </div>

          {/* for zoom in/out image if any */}
          {isPhotoOpen?.show &&
          listitem?.questionid === isPhotoOpen?.questionid ? (
            <Lightbox
              mainSrc={imagePreviewUrl}
              onCloseRequest={(e) => handleImageView(e, "close")}
            />
          ) : (
            ""
          )}
        </div>

        <div className="bg-gray-50 px-5 py-3 rounded-b-lg">
          <ResponseSet reponseCb={setResponseValue} value={responseValue}  isdisabled={restrictedInput}/>
          <CardInfo executeCb={true} callback={cardCallback} isOpen={isOpen} setIsOpen={setIsOpen} title="Attention" text="Vous ne pourrez plus rentrer votre réponse après avoir vu la réponse." />

          <div className="flex justify-between items-center">
            <button
              className="bg-FEA14E text-lg font-semibold text-white py-2 px-4 rounded-lg shadow-2xl inline-flex justify-center items-center focus:outline-none"
              onClick={() =>
                currentPage == 1 ? null : handlePagination(currentPage - 1)
              }
              disabled={((currentPage == 1 || (restrictedInput && attemptSetModal?.mode === "assessment") ) && isLoadingRequest == false) || isLoadingRequest }
            >
              {LocaleStrings.button_previous}
            </button>
            <span className="text-base 2xs:text-xl font-normal text-secondary px-2">
              {attemptSetModal?.mode === "assessment" &&
              listitem?.questionmode === "mcq" ? (
                ""
              ) : (
                <button
                  className="bg-secondary hover:bg-secondaryHover text-lg font-semibold text-primary px-4 py-2 border rounded-lg shadow-sm inline-flex justify-center items-center focus:outline-none"
                  // If Mode is assessment and question type is mcq, no need to flip the card
                  onClick={
                    attemptSetModal?.mode === "assessment" &&
                    listitem?.questionmode === "mcq"
                      ? null
                      : (e) => cardFlip(e)
                  }
                  disabled={(isFlipped && attemptSetModal?.mode === "assessment") || isLoadingRequest}
                >
                  {isFlipped
                    ? LocaleStrings.class_chapter_set_details_qst_view_qst_text
                    : LocaleStrings.class_chapter_set_details_qst_view_ans_text}
                </button>
                
              )}
            </span>
            {attemptSetModal?.mode === "practice" &&
            currentPage === pageDataLength ? (
              <button
                disabled={isLoadingRequest}
                className="bg-005051 text-lg font-semibold text-white py-2 px-6 rounded-lg shadow-2xl inline-flex justify-center items-center focus:outline-none"
                onClick={() => handleFinish("practice")}
              >
                {LocaleStrings.button_finish}
              </button>
              
            ) : attemptSetModal?.mode === "assessment" &&
              currentPage === pageDataLength ? (
              <button
                className="bg-005051 text-lg font-semibold text-white py-2 px-6 rounded-lg shadow-2xl inline-flex justify-center items-center focus:outline-none"
                onClick={
                  attemptedAnswer?.length > 0
                    ? () => handleFinish("assessment")
                    : null
                }
                disabled={attemptedAnswer?.length > 0 ? false : true}
              >
                {LocaleStrings.button_finish}
              </button>
            ) : (
              <button
                className="bg-4DB896 text-lg font-semibold text-white py-2 px-6 rounded-lg shadow-2xl inline-flex justify-center items-center focus:outline-none"
                onClick={() =>
                  currentPage * 1 >= pageDataLength &&
                  attemptedAnswer?.length == 0
                    ? null
                    : handlePagination(currentPage + 1)
                }
                disabled={(attemptedAnswer?.length > 0 || attemptSetModal?.mode == "practice") && isLoadingRequest}
              >
                {LocaleStrings.button_next}
              </button>
            )}
           
          </div>
          {attemptSetModal?.mode === "practice" && listitem.chatgpt_allowed == true &&
            <div class="flex justify-between items-center">
              <button onClick={() => clickAskAnotherQuestion({questionId:listitem.questionid})} class="bg-secondary hover:bg-secondaryHover text-lg font-semibold text-primary px-4 py-2 border rounded-lg shadow-sm inline-flex justify-center items-center focus:outline-none mx-auto mt-3">Générer une nouvelle question</button>
            </div>
          }

          {attemptSetModal?.mode === "practice" && isFlipped == true && listitem.chatgpt_allowed == true &&
            <div class="flex justify-between items-center">
              <button disabled={isLoadingRequest} onClick={() => clickAskHelp({questionId:listitem.questionid})} class="bg-secondary hover:bg-secondaryHover text-lg font-semibold text-primary px-4 py-2 border rounded-lg shadow-sm inline-flex justify-center items-center focus:outline-none mx-auto mt-3">réponse détaillée</button>
            </div>
          }
        </div>
      </div>

      {/* Cheking if answer already attepmted then not allowing to answer again */}
      {attemptSetModal?.mode === "assessment" &&
      isFlipped &&
      attemptedAnswer.length == 0 ? (
        <div className="mt-16 text-center space-y-3">
          <span className="text-xl 2xs:text-2xl font-normal text-primary">
            {LocaleStrings.setsattempt_text_your_ans}
          </span>
          <div className="flex items-center justify-center space-x-3">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent rounded-lg shadow-lg text-base 2xs:text-xl font-normal text-white bg-customred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={() => handleAnswer("incorrect")}
            >
              <ThumbDownIcon
                className="mr-2 h-4 w-4 2xs:h-6 2xs:w-6"
                aria-hidden="true"
              />
              {LocaleStrings.button_incorrect}
            </button>
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent rounded-lg shadow-lg text-base 2xs:text-xl font-normal text-white bg-4DB896 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={() => handleAnswer("correct")}
            >
              <ThumbUpIcon
                className="mr-2 h-4 w-4 2xs:h-6 2xs:w-6"
                aria-hidden="true"
              />
              {LocaleStrings.button_correct}
            </button>

          </div>
        </div>
      ) : attemptSetModal?.mode === "assessment" &&
        isFlipped &&
        attemptedAnswer.length > 0 ? (
        <div className="flex items-center justify-center space-x-3 mt-16">
          <span className="text-xl 2xs:text-2xl font-normal text-primary">
            {LocaleStrings.setsattempt_text_your_ans}
          </span>
          <span
            className={classNames(
              attemptedAnswer?.[0]?.answer === "correct"
                ? "text-customgreen"
                : "text-customred",
              "text-2xl 2xs:text-3xl font-normal capitalize"
            )}
          >
            {attemptedAnswer?.[0]?.answer === "correct"
              ? LocaleStrings.button_correct
              : LocaleStrings.button_incorrect}
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    attemptedSetQuestionList: state.attemptedSetQuestionList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { insertAttemptQuestion, fetchAttemptedSetQstList, attemptedSetFinished,askForNewQuestion,askForHelp },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
