import { CogIcon } from "@heroicons/react/outline";

import dashboard from "./assets/image/sidemenu/dashboard.svg";
import dashboard_sel from "./assets/image/sidemenu/dashboard_sel.svg";
import classes from "./assets/image/sidemenu/classes.svg";
import classes_sel from "./assets/image/sidemenu/classes_sel.svg";
import students from "./assets/image/sidemenu/students.svg";
import students_sel from "./assets/image/sidemenu/students_sel.svg";
import videos from "./assets/image/sidemenu/videos.svg";
import videos_sel from "./assets/image/sidemenu/videos_sel.svg";
import questionsets from "./assets/image/sidemenu/questionsets.svg";
import questionsets_sel from "./assets/image/sidemenu/questionsets_sel.svg";
import reports from "./assets/image/sidemenu/reports.svg";
import reports_sel from "./assets/image/sidemenu/reports_sel.svg";
import messages from "./assets/image/sidemenu/messages.svg";
import messages_sel from "./assets/image/sidemenu/messages_sel.svg";

import Login from "@signin";
import ResetPassword from "@resetpassword";
import Signup from "@signup";
import SignupUser from "@signupuserdetails";
import Dashboard from "@dashboard";
import Classes from "@classes";
import ClassDetails from "@classdetails";
import ChapterDetails from "@chapterdetails";
import SetDetails from "@questionsetdetails";
import Students from "@students";
import StudentDetails from "@studentdetails";
import Mysapce from "@myspace";
import Cognivideos from "@cognivideos";
import QuestionSets from "@questionsets";
import Reports from "@reports";
import Messages from "@messages";
import Settings from "@settings";
import UserProfile from "@userprofile";
import UserPassword from "@userpassword";
import UserPlan from "@userplan";
import UserPlanSuccess from "@userplansuccess";
import UserPlanFail from "@userplanfailed";
import UserInvoice from "@userinvoice";
import History from "@history";
import Stats from "@stats";
import TeacherExamDate from "./components/examsdate/teacherExamDateView.js"

import Ai from "./components/ai/index.js";
// Languages
import LocaleStrings from "@language";


var routes = [
  {
    path: "/login",
    name: "",
    icon: "",
    component: <Login />,
    display: false,
    key: "login",
    layout: "/auth",
  },
  {
    path: "/signup",
    name: "",
    icon: "",
    component: <Signup />,
    display: false,
    key: "signup",
    layout: "/auth",
  },
  {
    path: "/signupuser",
    name: "",
    icon: "",
    component: <SignupUser />,
    display: false,
    key: "signupuser",
    layout: "/auth",
  },
  {
    path: "/resetpassword",
    name: "",
    icon: "",
    component: <ResetPassword />,
    display: false,
    key: "login",
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: LocaleStrings.dashboard,
    icon: dashboard,
    selected: dashboard_sel,
    component: <Dashboard />,
    display: true,
    key: "dashboard",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/history",
    name: "Mes résultats",
    icon: questionsets,
    selected: questionsets_sel,
    component: <History />,
    display: true,
    key: "history",
    section: "student",
    position: "second",
    layout: "/admin",
  },
  {
    path: "/classes",
    search:'?myclass=true',
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <Classes />,
    display: true,
    key: "classes",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/classes",
    search:'?myclass=false',
    name: "Bibliothèque de quizzs Kojyto",
    icon: classes,
    selected: classes_sel,
    component: <Classes />,
    display: true,
    key: "classes",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/classes/:classid",
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <ClassDetails />,
    display: false,
    key: "classes",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/classes/:classid/:chapterid",
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <ChapterDetails />,
    display: false,
    key: "classes",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/classes/:classid/:chapterid/:setid",
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <SetDetails />,
    display: false,
    key: "classes",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/students",
    name: LocaleStrings.students,
    icon: students,
    selected: students_sel,
    component: <Students />,
    display: true,
    key: "students",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/students/:userid",
    name: LocaleStrings.students,
    icon: students,
    selected: students_sel,
    component: <StudentDetails />,
    display: false,
    key: "students",
    section: "teacher",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/myspace",
    name: LocaleStrings.myspace,
    icon: classes,
    selected: classes_sel,
    component: <Mysapce />,
    display: true,
    key: "myspace",
    section: "student",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/myspace/:classid",
    name: LocaleStrings.myspace,
    icon: classes,
    selected: classes_sel,
    component: <ClassDetails />,
    display: false,
    key: "myspace",
    section: "student",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/myspace/:classid/:chapterid",
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <ChapterDetails />,
    display: false,
    key: "myspace",
    section: "student",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/myspace/:classid/:chapterid/:setid",
    name: LocaleStrings.classes,
    icon: classes,
    selected: classes_sel,
    component: <SetDetails />,
    display: false,
    key: "myspace",
    section: "student",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/videos",
    name: LocaleStrings.videos,
    icon: videos,
    selected: videos_sel,
    component: <Cognivideos />,
    display: true,
    key: "videos",
    section: "student",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/questionsets",
    name: "Mes quizz",
    icon: questionsets,
    selected: questionsets_sel,
    component: <QuestionSets />,
    display: true,
    key: "questionsets",
    section: "both",
    position: "second",
    layout: "/admin",
  },
  {
    path: "/reports",
    name: LocaleStrings.reports,
    icon: reports,
    selected: reports_sel,
    component: <Reports />,
    display: true,
    key: "reports",
    section: "teacher",
    position: "second",
    layout: "/admin",
  },
  {
    path: "/messages",
    name: LocaleStrings.message,
    icon: messages,
    selected: messages_sel,
    component: <Messages />,
    display: true,
    key: "messages",
    section: "both",
    position: "second",
    layout: "/admin",
  },
  {
    path: "/settings",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <Settings />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/profile",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserProfile />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/password",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserPassword />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/plans",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserPlan />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/plans/success",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserPlanSuccess />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/plans/failed",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserPlanFail />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/settings/invoices",
    name: LocaleStrings.settings,
    icon: CogIcon,
    component: <UserInvoice />,
    display: false,
    key: "settings",
    section: "both",
    position: "main",
    layout: "/admin",
  },
  {
    path: "/stats",
    name: "",
    icon: "",
    component: <Stats />,
    display: false,
    section: "both",
    position: "main",
    key: "stats",
    layout: "/admin",
  },
  {
    path: "/examdate",
    name: "Examens",
    icon: questionsets,
    selected: questionsets_sel,
    component: <TeacherExamDate />,
    display: true,
    key: "questionsets",
    section: "teacher",
    position: "second",
    layout: "/admin",
  },
 /* {
    path: "/ai",
    name: "Kojyto AI",
    icon: questionsets,
    selected: questionsets_sel,
    component: <Ai />,
    display: true,
    key: "questionsets",
    section: "both",
    position: "main",
    submenu: {
      "Traducteur": {
        component: <Ai />,
      },
      "kojyto chat": {
        component: <Ai />,
      },
    },
    isOpen: false,
    layout: "/admin",
  },*/
];
export default routes;
