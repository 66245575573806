import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";

function useAutosizeTextarea(value) {
    const textAreaRef = useRef(null);
  
    useEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = '0px';
        const { scrollHeight } = textAreaRef.current;
        if(scrollHeight > 270){ 
            return textAreaRef.current.style.height = `270px`;
        }
        textAreaRef.current.style.height = `${scrollHeight + 5}px`;
      }
    }, [textAreaRef, value]);
  
    return textAreaRef;
  }

function MultipleChoiceAssessment() {
    const [userInput, setUserInput] = useState('');
    const textAreaRef = useAutosizeTextarea(userInput);
    const chatBoxRef = useRef(null);
    const [autoScroll,setAutoScroll] = useState(false);
    const [messages, setMessages] = useState([]);
    const lastScrollTop = useRef(0);
    const handleInputChange = (e) => {
      setUserInput(e.target.value);
    };

    useEffect(() => {
        if(autoScroll == true)
            chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }, [messages]);

    const setMessagesForStream = (message, id) => {
        setMessages((prevMessages) => {
            let find = false;
            const newMessages = prevMessages.map((element) => {
                if (element.id === id) {
                    find = true;
                    return { ...element, message: element.message + message };
                }
                return element;
            });
    
            if (!find) {
                newMessages.push({ type: 'ia', message: message, id: id });
            }
    
            return newMessages;
        });
    };
  
    const handleSend = async (e) => {
        setAutoScroll(true);
        e.preventDefault();
        setUserInput(""); // Réinitialiser l'entrée utilisateur
        setMessages((prevMessages) => [...prevMessages, { type: 'user', message: userInput, id: prevMessages.length + 1 }]);
        const response = await fetch("http://api.kojyto.fr:8088/api/v1/traductor", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: userInput }),
        });
    
        // Vérifiez si la réponse a un corps de flux
        if (!response.body) {
          console.error("ReadableStream not supported in this browser.");
          return;
        }
    
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;
        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          const chunkValue = ('[' + decoder.decode(value, { stream: true }) + "]").replace(",]", "]");
          var data = JSON.parse(chunkValue)
          var str = "";
          data.forEach(element => {
            str += element.content;
          });
          if(data.length !== 0){
            setMessagesForStream(str,data[0].id);
            //setResponseText((prev) => prev + str);
          }
        }
    };
    //streamTextPart

    function stopAutoScroll(e) {
        if (e.target.scrollTop < lastScrollTop.current) {// if we try to scroll up
            console.log("passe")
            setAutoScroll(false);
        }
        lastScrollTop.current = e.target.scrollTop;
    }

  return (
    <div className="h-max-[100vh] flex items-center justify-center bg-gray-50 p-4">
        <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-md">
        {/* Header Section */}
        <div className="flex flex-col items-center">
            <div className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 text-center">Kojyto ai 🎓</div>
        </div>

        {/* Chat Area */}
        <div ref={chatBoxRef} className="overflow-y-auto h-[50vh] min-h-[20em]" onScroll={stopAutoScroll} onClick={() => setAutoScroll(false)}  >
            {/* User Input */}
            {messages.map((message, index) => (
                message.type === "ia" ? (
                    <div key={index} className="mr-4 mt-4 bg-indigo-300 p-4 rounded-lg text-base sm:text-lg lg:text-xl text-gray-900">
                        <p dangerouslySetInnerHTML={{__html:message.message.replace(/\n/g, '<br>')}}></p>
                    </div>
                ) : (
                    <div key={index} className="mr-4 mt-4 bg-gray-100 p-4 rounded-lg text-base sm:text-lg lg:text-xl text-gray-900">
                         <p dangerouslySetInnerHTML={{__html:message.message.replace(/\n/g, '<br>')}}></p>
                    </div>
                )
            ))}
            
        </div>

        {/* Quick Action Buttons */}
        <div className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          
        </div>

        {/* Text Input Area */}
        <div className="mt-8 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <textarea
            ref={textAreaRef}
            type="text"
            className="flex-1 p-4 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
            placeholder="Tape ton message ici..."
            value={userInput}
            onChange={handleInputChange}
            rows={1}
            />
            <button
            onClick={handleSend}
            className="w-full sm:w-auto p-4 bg-theme text-white rounded-lg"
            >
            Envoyer
            </button>
        </div>
        </div>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({},
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceAssessment);
